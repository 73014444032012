import React, { useState } from "react"
import OutsourcingIMG from "../../../../../assets/img/outsourcing-segments.png"
import "./style.scss"
import StarsIcon from "@mui/icons-material/Stars"
import { Grow } from "@material-ui/core"
import VizSensor from "react-visibility-sensor"

const OutsourcingSegments = () => {
  let [active, setActive] = useState(false)

  return (
    <>
      <div className="outsourcing-segments-page">
        <h1 className="segments-title">Segmentos</h1>
        <p className="segments-subtitle">Lorem ipsum dolor sit amet.</p>
        <div className="about-framework">
          <div className="segments-wrapper">
            <div className="segments-items">
              <VizSensor
                onChange={isVisible => {
                  setActive(isVisible)
                }}
              >
                <>
                  <Grow
                    in={active}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(active ? { timeout: 1000 } : {})}
                  >
                    <div className="segment-item">
                      <div className="segment-icon">
                        <StarsIcon fontSize="large" />
                      </div>
                      <p>
                        <h3 className="segment-title">Expertise</h3>
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </Grow>
                  <Grow
                    in={active}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(active ? { timeout: 1000 } : {})}
                  >
                    <div className="segment-item">
                      <div className="segment-icon">
                        <StarsIcon fontSize="large" />
                      </div>
                      <p>
                        <h3 className="segment-title">Capacitação</h3>
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </Grow>
                  <Grow
                    in={active}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(active ? { timeout: 1000 } : {})}
                  >
                    <div className="segment-item">
                      <div className="segment-icon">
                        <StarsIcon fontSize="large" />
                      </div>
                      <p>
                        <h3 className="segment-title">Networking</h3>
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                        Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </Grow>
                </>
              </VizSensor>
            </div>
            <div className="outsourcing-lower-title">
              <h3>Outsourcing confiável</h3>
              <p>Profissionais capacitados e experientes.</p>
            </div>
          </div>
          <img src={OutsourcingIMG} alt="framework" />
        </div>
      </div>
    </>
  )
}

export default OutsourcingSegments
